var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Sensors"}},'loader',{ loading: _vm.loading },false),[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h3',{staticClass:"title is-4"},[_vm._v("Sensors")])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.sensors),function(sensor){return _c('tr',{key:sensor.id},[_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'asset-sensor-dashboard',
                    params: {
                      asset: _vm.asset.id,
                      sensor: sensor.id
                    }
                  }}},[_vm._v(" "+_vm._s(sensor.personal_name)+" ("+_vm._s(sensor.name)+") ")])],1),_c('td',[_vm._v(" "+_vm._s(sensor.description)+" ")]),_c('td',[_c('dropdown-wrapper',{attrs:{"position":"bottom-end"},scopedSlots:_vm._u([{key:"trigger",fn:function({ isActive }){return _c('table-actions-trigger',_vm._b({},'table-actions-trigger',{ isActive },false))}}],null,true)},[_c('dropdown-item',{on:{"click":function($event){return _vm.editSensorPersonalName(sensor)}}},[_c('icon',{attrs:{"icon":"edit"}}),_c('span',[_vm._v("Rename")])],1)],1)],1)])}),0)]),(_vm.sensors.length === 0)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-centered"},[_c('p',{staticClass:"mtb-1"},[_vm._v(" There are no sensors to display. ")])])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }