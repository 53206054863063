var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":`Loading ${_vm.Naming.Inspection} History`}},'loader',{ loading: _vm.loading },false),[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h3',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.Naming.Inspection)+" History")])]),_c('div',{staticClass:"column is-5"},[_c('date-picker',{attrs:{"mode":"range"},on:{"input":_vm.setDateRange},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('div',{staticClass:"column is-narrow"},[_c('action-button',{staticClass:"is-info",attrs:{"slot":"right","working":_vm.downloadingInspection,"left-icon":"download"},on:{"click":_vm.downloadInspectionReport},slot:"right"},[_vm._v(" Pull Report ")])],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Actions")]),_c('th',[_vm._v(_vm._s(_vm.Naming.Job))]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v(_vm._s(_vm.Naming.Checklist))]),_c('th',[_vm._v(_vm._s(_vm.Naming.Technician))]),_c('th',[_vm._v(_vm._s(_vm.Naming.JobType))]),_c('th',{staticClass:"is-narrow has-text-right"},[_vm._v("Outcome")])])]),_c('tbody',_vm._l((_vm.inspectionHistory.data),function(inspection){return _c('tr',{key:inspection.id},[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'inspection-evidence',
                params: {
                  inspection: inspection.id,
                  site: inspection.job.site_id,
                  zone: _vm.asset.zone_id,
                  asset: _vm.asset.id
                }
              }}},[_c('strong',[_vm._v("View Report")])])],1),_c('td',[_vm._v(" "+_vm._s('#' + inspection.job.number)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(inspection.created_at,'L LTS'))+" ")]),_c('td',[_vm._v(" "+_vm._s(inspection.checklist ? inspection.checklist.name : '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(inspection.technician ? inspection.technician.full_name : '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(inspection.job.type ? inspection.job.type.description : '-')+" ")]),_c('td',[(!inspection.passed_at && !inspection.failed_at)?_c('span',[_vm._v("In Progress")]):_c('yes-no',{attrs:{"value":Boolean(inspection.passed_at),"yes-text":"Passed","no-text":"Failed"}})],1)])}),0)]),(!_vm.inspectionHistory.data.length)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-centered"},[_c('p',{staticClass:"mtb-1"},[_vm._v(" There are no "+_vm._s(_vm.Naming.Inspections.toLowerCase())+" to display. ")])])]):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('pager',{attrs:{"working":_vm.loadingPage,"pageable":_vm.inspectionHistory,"jump-controls":"","context":_vm.Naming.Inspection.toLowerCase()},on:{"nav":_vm.goToPage}})],1),_c('div',{staticClass:"column is-narrow"},[(_vm.hasAbility('Billow\\Models\\Asset', 'view-deleted-inspection-history'))?_c('router-link',{staticClass:"button is-small",attrs:{"to":{
              name: 'asset-trashed-inspections',
              params: _vm.$route.params
            }}},[_c('icon',{attrs:{"icon":"trash"}}),_c('span',[_vm._v("Deleted "+_vm._s(_vm.Naming.Inspections))])],1):_vm._e()],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }