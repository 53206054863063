<template>
  <tr>
    <td class="is-narrow">{{ item.date | date('L LTS') }}</td>
    <td>{{ item.parsed_type }}</td>
    <td>{{ item.parsed_details }}</td>
    <td class="is-narrow">{{ item.user.name }}</td>
    <td class="is-narrow">
      {{ item.job_number }}
    </td>
    <td class="is-narrow">
      {{ item.job_type }}
    </td>
    <td class="is-narrow">
      {{ item.job_reference }}
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>