<template>
  <section class="auth-view is-flex flex-column align-center justify-center is-fullheight">
    <form class="grid gap-3 has-animation-present animation-delay-300">
      <div class="has-text-centered">
        <img class="logo" :src="logoUrl" alt="">
      </div>
      <div class="box">
        <div class="box-header pt-2 pb-1 plr-2">Two Factor Verification</div>
        <p class="mt-2">
          Enter the 6 digit code from the two-factor app on your mobile device.<br>
          Or you may <a class="has-text-link" @click="switchToRecovery">try one of your recovery codes.</a>
        </p>
        <p class="mt-2">
          If you have lost your MFA device or cannot authenticate<br>please contact your administrator to reset and disable your MFA
        </p>
        <div class="p-2 grid gap-1">
          <text-input 
            v-if="normal"
            v-model="verification.code"
            classes="is-large"
            required>
            6 Digit Code
          </text-input>
          <text-input 
            v-else
            v-model="verification.recovery_code"
            classes="is-large"
            required>
            Recovery Code
          </text-input>
        </div>

        <div class="box-footer pt-1 pb-2 plr-2 is-flex align-center justify-center">
          <submit-button :working="working" @submit="validateCode" class="is-primary is-large">
            Verify Code
          </submit-button>
        </div>
      </div>
      <footer>{{ `${appName} ${version}` }}</footer>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {

  data: () => ({
    working: false,
    normal: true,
    version: process.env.VUE_APP_VERSION,
    verification: {
      code: '',
      recovery_code: ''
    }
  }),

  created() {
    if(!this.parked_token) return this.$router.push({
      name: 'signin'
    })
  },

  methods: {
    validateCode() {
      this.working = true
      this.$store.dispatch('auth/twoFaChallenge', this.verification).then(() => {
        this.loadCompany()
      }).catch(error => {
        this.working = false
        console.log(error)
      })
    },

    loadCompany() {
      this.$store.dispatch('company/load').then(() => {
        this.working = false
        this.$router.push({ name: 'dashboard' })
      }).catch(error => {
        this.working = false
        console.log(error)
      })
    },
    switchToRecovery() {
      this.normal = !this.normal
    }
  },

  computed: {
    ...mapState('auth', [
      'parked_token'
    ]),
    logoUrl() {
      return process.env.VUE_APP_COLOUR_LOGO
    },
    appName() {
      return process.env.VUE_APP_NAME
    }
  },

}
</script>
