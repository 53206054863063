<template>
  <div class="columns">
    <div class="column">
      <text-input disabled :value="field.label">Attribute</text-input>
    </div>
    <div class="column">
      <text-input-list
          :value="fieldValue"
          :data-list="fieldOptions"
          @input="updateAttribute"
      >Value
      </text-input-list>
    </div>
    <div class="column is-narrow">
      <field-label>&nbsp;</field-label>
      <action-button @click="removeAttribute" class="is-danger">
        <icon icon="trash"/>
      </action-button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    updateAttribute(value) {
      this.$store.commit('asset/updateAttribute', {
        fieldId: this.field.field_id,
        value: value,
      })
    },
    async removeAttribute() {
      if (await this.$confirm({
        title: 'Remove Attribute',
        message: 'Are you sure you want to delete this attribute?'
      })) {
        this.$store.commit('asset/removeAttribute', this.field.field_id)
      }
    }
  },

  computed: {
    ...mapGetters('asset', [
      'asset'
    ]),
    fieldValue() {
      if (this.asset.meta_data && this.asset.meta_data[this.field.field_id]) {
        return this.asset.meta_data[this.field.field_id]
      }
      return ''
    },
    fieldOptions() {
      let customFieldOptions = this.asset.type.custom_fields
      let customFieldOptionsAsJson = JSON.parse(customFieldOptions);
      return customFieldOptionsAsJson.find(field => field.field_id === this.field.field_id)?.options
    },
  }
}
</script>