var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('div',{staticClass:"box p-2"},[_c('div',{staticClass:"is-flex justify-between"},[_c('div',{staticClass:"is-flex align-items-center"},[_c('h1',{staticClass:"title is-size-4"},[_vm._v("Dynamic Statuses")]),_c('icon',{staticClass:"ml-1 mb-3 is-small has-text-grey-lighter has-tooltip-right has-tooltip-multiline",attrs:{"icon":"info-circle","data-tooltip":_vm.statusesInfo}})],1),(_vm.$root.hasAbility('manage-dynamic-statuses', 'App\\Models\\DynamicStatus'))?_c('router-link',{staticClass:"button is-primary has-text-weight-medium",attrs:{"to":{
                      name: 'asset-type-dynamic-statuses',
                      params: {
                        assetType: _vm.asset.asset_type_id
                      }
                  }}},[_c('icon',{staticClass:"mr-1",attrs:{"icon":"angle-double-right"}}),_vm._v(" Edit Dynamic Statuses ")],1):_vm._e()],1),(_vm.dynamicStatuses.length < 1)?_c('p',{staticClass:"has-text-grey-light mb-2"},[_vm._v(" There are no dynamic statuses for this "+_vm._s(_vm.Naming.Asset)+"'s "+_vm._s(_vm.Naming.AssetType)+" yet."),_c('br'),(_vm.$root.hasAbility('manage-dynamic-statuses', 'App\\Models\\DynamicStatus'))?_c('span',[_c('router-link',{staticClass:"has-text-link",attrs:{"to":{
                        name: 'asset-type-dynamic-statuses',
                        params: {
                          assetType: _vm.asset.asset_type_id
                        }
                     }}},[_vm._v(" Click here ")]),_vm._v(" to add a new dynamic status for this "+_vm._s(_vm.Naming.AssetType)+". ")],1):_vm._e()]):_c('div',[_c('table',{staticClass:"table is-fullwidth mb-2"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Options Count")]),_c('th',[_vm._v("Status Assigned At")]),_c('th',[_vm._v("Selected Status")])])]),_c('tbody',_vm._l((_vm.dynamicStatuses),function(status){return _c('dynamic-status',{key:status.id,attrs:{"status":status,"activeStatuses":_vm.asset.active_dynamic_statuses},on:{"reload":_vm.reloadAsset}})}),1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }