<template>
<div class="box">
  <div class="columns">
    <transfer-tool v-if="canTransferAssets()"/>
    <decommission-tool v-if="hasAbility('decommission-assets')"/>
    <assign-tool v-if="hasAbility('manage-sub-assets')"/>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import AssignTool from '../partials/AssignTool' 
import DecommissionTool from '../partials/DecommissionTool' 
import TransferTool from '../partials/TransferTool' 

export default {

  components: {
    TransferTool,
    DecommissionTool,
    AssignTool
  },

  methods: {
    hasAbility(name) {
      return this.user.abilities.filter(ability => ability.name === name).length > 0
    },
    canTransferAssets(){
      if(
        this.hasAbility('transfer-assets-same-client') ||
        this.hasAbility('transfer-assets-between-clients')
        ){
        return true
      }
      else{
        return false
      }
    }    
  },

  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
  },

  created() {
      this.$store.dispatch('asset/loadAsset', this.$route.params.asset)
  },  

}
</script>
