<template>
<page icon="sad-tear" title="Account Disabled" v-bind="{ loading }">
  <div class="box">
    <h1 class="is-size-3 has-text-grey">Account Disabled</h1>
    <p class="mb-2">
      Your account is currently disabled. If you believe this is an error please <strong><router-link :to="{ name: 'support.tickets' }">click here.</router-link></strong>
    </p>
    <p class="is-size-6">
      To re-activate your account, contact {{ appName }} using the email address {{ company.contact_us_email ? company.contact_us_email : "hello@assetpoolgroup.com"  }} or raise a support ticket above.
    </p>
  </div>
</page>  
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true
  }),

  created () {
    this.$store.dispatch('subscription/loadSubscription', this.company.id).then(() => {
      this.loading = false
    }).catch(error => {
      this.loading = false
    })
  },

  computed: {
    ...mapGetters('company', [
      'company'
    ]),
    ...mapGetters('subscription', [
      'subscription'
    ]),
    hasUnpaidInvoices() {
      return this.subscription.invoices.filter(invoice => !invoice.settled_at).length > 0
    },
    appName() {
      return process.env.VUE_APP_NAME
    }
  }

}
</script>
