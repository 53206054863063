<template>
  <section class="auth-view is-flex flex-column align-center justify-center is-fullheight">
    <form class="grid gap-3 has-animation-present animation-delay-300">
      <div class="has-text-centered">
        <img class="logo" :src="logoUrl" alt="">
      </div>
      <div class="box is-paddingless">
        <div class="box-header pt-2 pb-1 plr-2">Reset your Password</div>
        <div class="p-2 grid gap-1">
          <text-input
            :value="forgotPassword.email"
            :error="$root.errors.email"
            @input="forgotPasswordEmail"
            autofocus
            type="email"
            :label="false"
            class="is-marginless"
            classes="is-medium"
            placeholder="Email Address"
            left-icon="envelope"/>
          <action-button
            v-bind="{ working }"
            @click="requestReset"
            class="is-primary is-rounded is-medium has-text-weight-bold">
            Request Reset
          </action-button>
          <div class="is-flex align-center justify-center">
            <a @click="signIn" class="has-text-cool-blue-text">Sign In</a>
          </div>
        </div>
      </div>
      <footer>{{ appName }} {{ version }}</footer>
    </form>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    version: process.env.VUE_APP_VERSION,
  }),

  methods: {
    ...mapMutations('auth', [
      'forgotPasswordEmail',
    ]),

    requestReset() {
      this.working = true
      this.$store.dispatch('auth/requestPasswordReset')
        .then(async () => {
          await this.$alert(
            this.$lang.auth.passwordResetRequested.title,
            this.$lang.auth.passwordResetRequested.message
          )
          this.$router.replace({ name: 'signin' })
        })
        .catch(error => console.error(error))
        .then(() => this.working = false)
    },

    signIn() {
      this.$router.push({
        name: 'signin'
      })
    },
  },

  computed: {
    ...mapState('auth', [
      'forgotPassword'
    ]),
    appName() {
      return process.env.VUE_APP_NAME
    },
    logoUrl() {
      return process.env.VUE_APP_COLOUR_LOGO
    },
  },

}
</script>
