<template>
  <form>
    <div class="columns is-multiline">
      <data-selector
        v-show="canTransferDifferentClient()"
        class="column is-8"
        searchable
        right-icon="chevron-down"
        v-model="selectedClientId"
        :items="filteredClients"
        value-key="id"
        label-key="legal_name">
        <action-button
          class="is-primary"
          v-if="selectedClientId"
          slot="right"
          @click="clearClientAndSite()">
          <icon icon="times"/>
        </action-button>Select {{ Naming.Client }} to Transfer {{ Naming.Asset }} To
      </data-selector>
      <data-selector
        v-show="selectedClientId !== null"
        class="column is-8"
        searchable
        right-icon="chevron-down"
        v-model="selectedSiteId"
        :items="siteList"
        :disabled="siteList.length < 1"
        value-key="id"
        label-key="name">
        <action-button
          class="is-primary"
          v-if="selectedSiteId"
          slot="right"
          @click="clearSite()">
          <icon icon="times"/>
        </action-button>Select {{ Naming.Site }} to Transfer {{ Naming.Asset }} To
      </data-selector>
      <div class="column is-8 is-flex justify-between align-center mt-0" v-if="selectedClient && siteList.length < 1">
        <p class="has-text-weight-bold">There are no available {{ Naming.Sites.toLowerCase() }} for {{ selectedClient.legal_name }}.</p>
        <router-link v-if="isAdmin" class="button is-small is-info mt-1 mb-1"
          :to="{ name: 'site-admin-create', query: { client: selectedClient.id }}">
          <span>Add {{ Naming.Site }}</span>
          <icon icon="chevron-right"/>
        </router-link>
      </div>  
      <select-zone
        class="column is-8"
        v-show="showingZoneSelector === true"
        :asset="asset" 
        :selectedSite="selectedSite"
        @zoneSelected="zoneSelected"         
        />      
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import SelectZone from "../partials/SelectZone";

export default {

  components: {
    SelectZone
  },
  props: {
    asset: {
      type: Object,
      default: () => {}
    },
    site: {
      type: Object,
      default: () => {}
    },
  },

  data: () => ({
    isLoading: true,
    siteList: [],
    selectedClientId: null,
    selectedSiteId: null,
    selectedZone: null,
  }),

  methods: {
    setDefaultClient(){
      if(this.canTransferSameClient() || !this.canTransferDifferentClient()){
        this.selectedClientId = this.site.client_id
      }
    },
    canTransferDifferentClient() {
      return find(this.user.abilities, ability => ability.name === 'transfer-assets-between-clients') ?? false
    },
    canTransferSameClient() {
      return find(this.user.abilities, ability => ability.name === 'transfer-assets-same-client') ?? false      
    },
    clearClientAndSite(){
      this.selectedClientId = null
      this.clearSite()
    },
    clearSite(){
      this.selectedSiteId = null
      this.$emit('siteSelected', null)
    },
    async fetchSites(clientId){
      if(this.selectedClientId !== null){
        await this.$store.dispatch('site/loadClientSiteList', clientId).then(response =>
        {
          this.siteList = response
        })
      }
    },
    zoneSelected(zone){
      this.selectedZone = zone
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
    ...mapGetters('client', [
      'clientList',
    ]),
    selectedClient() {
      return _.find(this.clientList, client => client.id === this.selectedClientId) ?? null
    },
    selectedSite() {
      return _.find(this.siteList, site => site.id === this.selectedSiteId) ?? null
    },       
    filteredClients(){
      if(!this.canTransferSameClient()){
        return _.filter(this.clientList, client => client.id !== this.site.client_id)
      }
      return this.clientList
    },
    showingZoneSelector(){
      return this.selectedSite !== null ? true : false
    }    
  },

  watch: {
    selectedClientId() {
      this.$emit('clientSelected', this.selectedClient)
      this.fetchSites(this.selectedClientId)
    },
    selectedSiteId() {
      this.$emit('siteSelected', this.selectedSite)
    },
    selectedZone(){
      this.$emit('zoneSelected', this.selectedZone)
    }
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('client/loadClient', this.site.client_id),
      this.setDefaultClient()
    ]).then((response) => {
      if(!this.canTransferDifferentClient()){
        this.siteList = response[0].sites
      }
      this.isLoading = false
    })
  },
}
</script>
