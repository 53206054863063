<template>
  <div class="is-marginless">
    <data-selector
      right-icon="chevron-down"
      searchable
      v-model="selectedZoneId"
      :disabled="!filteredZones.length"
      :items="filteredZones"
      value-key="id"
      label-key="name">
      <action-button
        class="is-primary"
        v-if="selectedZoneId"
        slot="right"
        @click="clearZone()">
        <icon icon="times"/>
      </action-button>Select {{ Naming.Zone }} to Transfer {{ Naming.Asset }} To
    </data-selector>
    <div v-if="selectedSite && selectedSite.zones.length < 1">
      <message class="is-danger">
        The {{ selectedSite.name }} {{ Naming.Site.toLowerCase() }} does not have any {{ Naming.Zones.toLowerCase() }}. <br>
        <router-link class="button is-danger mt-1"
          :to="{ name: 'zone-create', params: { site: selectedSite.id }}">
          <span>Add {{ Naming.Zone }}</span>
          <icon icon="chevron-right"/>
        </router-link>
      </message>
    </div>  
    <div v-if="selectedSite && selectedSite.zones.length > 0 && filteredZones.length < 1">
      <message class="is-danger">
        The {{ selectedSite.name }} {{ Naming.Site.toLowerCase() }} does not have any {{ Naming.Zones.toLowerCase() }} that allow the following {{Naming.AssetTypes.toLowerCase()}}:
        <h5 class="is-size-6 ml-1" v-for="assetType in transferringAssetTypes" v-bind:key="assetType.id"><strong>- {{assetType.name}}</strong></h5>
        <router-link class="button is-danger mt-1"
          :to="{ name: 'site-zones', params: { site: selectedSite.id }}">
          <span>Configure {{ Naming.Zones }}</span>
          <icon icon="chevron-right"/>
        </router-link>
      </message>
    </div>   
  </div> 
</template>

<script>

export default {

  props: {
    asset: {
      type: Object,
      default: () => {}
    },
    selectedSite: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    selectedZoneId : null,
  }),

  methods: {
    clearZone(){
      this.selectedZoneId = null
    }
  },

  computed: {
    selectedZone(){
      return _.find(this.filteredZones, zone => zone.id === this.selectedZoneId) ?? null
    },
    transferringAssetTypes() {
      let assetTypes = [];
      assetTypes.push(this.asset.type);
      _.forEach(this.asset.sub_assets, (asset) => assetTypes.push(asset.type));
      return _.uniqBy(assetTypes, "id");
    },
    filteredZones() {
      if (this.selectedSite === null) {
        return []
      }

      let transferringAssetTypeIds = _.map(this.transferringAssetTypes, 'id')
      let zones = this.selectedSite.zones;

      zones = _.filter(zones, zone => zone.id !== this.asset.zone_id)

      zones = _.filter(zones, zone => {
        let allowedZoneAssetTypeIds = _.map(zone.type.asset_types, 'id')
        allowedZoneAssetTypeIds = _.uniq(allowedZoneAssetTypeIds)

        for (const transferringAssetTypeId of transferringAssetTypeIds) {
          if (allowedZoneAssetTypeIds.indexOf(transferringAssetTypeId) === -1) {
            return false
          }
        }

        return true
      })

      return zones
    },    
  },

  watch: {
    selectedZoneId() {
      this.$emit('zoneSelected', this.selectedZone)
    },
  },  
}
</script>
