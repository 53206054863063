<template>
  <loader v-bind="{ loading }" :text="`Loading ${Naming.Inspection} History`">
    <div class="box">
      <div class="columns">
        <div class="column">
          <h3 class="title is-4">{{ Naming.Inspection }} History</h3>
        </div>
        <div class="column is-5">
          <date-picker
            mode="range"
            v-model="range"
            @input=setDateRange>
          </date-picker>
        </div>
        <div class="column is-narrow">
          <action-button
            slot="right"
            class="is-info"
            :working="downloadingInspection"
            @click="downloadInspectionReport"
            left-icon="download">
            Pull Report
          </action-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth">
            <thead>
            <tr>
              <th>Actions</th>
              <th>{{ Naming.Job }}</th>
              <th>Date</th>
              <th>{{ Naming.Checklist }}</th>
              <th>{{ Naming.Technician }}</th>
              <th>{{ Naming.JobType }}</th>
              <th class="is-narrow has-text-right">Outcome</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="inspection in inspectionHistory.data" :key="inspection.id">
              <td>
                <router-link
                  :to="{
                  name: 'inspection-evidence',
                  params: {
                    inspection: inspection.id,
                    site: inspection.job.site_id,
                    zone: asset.zone_id,
                    asset: asset.id
                  }
                }">
                  <strong>View Report</strong>
                </router-link>
              </td>
              <td>
                {{ '#' + inspection.job.number }}
              </td>
              <td>
                {{ inspection.created_at | date('L LTS') }}
              </td>
              <td>
                {{ inspection.checklist ? inspection.checklist.name : '-' }}
              </td>
              <td>
                {{ inspection.technician ? inspection.technician.full_name : '-' }}
              </td>
              <td>
                {{ inspection.job.type ? inspection.job.type.description : '-'}}
              </td>
              <td>
                <span v-if="!inspection.passed_at && !inspection.failed_at">In Progress</span>
                <yes-no v-else :value="Boolean(inspection.passed_at)" yes-text="Passed" no-text="Failed"/>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="columns" v-if="!inspectionHistory.data.length">
            <div class="column has-text-centered">
              <p class="mtb-1">
                There are no {{ Naming.Inspections.toLowerCase() }} to display.
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <pager
                :working="loadingPage"
                :pageable="inspectionHistory"
                @nav="goToPage"
                jump-controls
                :context="Naming.Inspection.toLowerCase()"/>
            </div>
            <div class="column is-narrow">
              <router-link class="button is-small" :to="{
                name: 'asset-trashed-inspections',
                params: $route.params
              }" v-if="hasAbility('Billow\\Models\\Asset', 'view-deleted-inspection-history')">
                <icon icon="trash"/>
                <span>Deleted {{ Naming.Inspections }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { asset as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    loaded: false,
    loadingPage: false,
    downloadingInspection: false,
    range: ''
  }),

  created() {
    this.setDateRange(
      moment().format('Y-MM-DD')
    )
    this.loadInspections()
  },

  methods: {
    setDateRange(value) {
      this.range = value
    },
    loadInspections() {
      this.$store.dispatch('asset/loadInspectionHistory', {
        path: 'api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    downloadInspectionReport() {
      this.downloadingInspection = true
      backend.downloadInspectionReportByDateRange({
        asset: this.asset.id,
        date_range: this.range
      }, ({ data }) => {
        this.downloadingInspection = false
        download(data, `#${this.asset.id}-${this.asset.code}-inspection-${this.range}.pdf`, 'application/pdf')
      }, error => {
        if (error.response.status === 422) {
          this.$toast.warning(this.Convert('There are no inspections to export for the selected date range. Try selecting a wider date range.'))
        }
        this.downloadingInspection = false
      })
    },
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    }
  },

  computed: {
    ...mapGetters('asset', [
      'asset',
      'inspectionHistory'
    ]),
    ...mapGetters('auth', [
      'user'
    ])
  },

  watch: {
    '$route': 'loadInspections'
  }
}
</script>
