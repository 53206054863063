<template>
  <div class="box">
    <h3 class="title">{{ Naming.Asset }} Already Assigned as Component</h3>
    <div class="has-text-">
      <p>
        This {{ Naming.Asset.toLowerCase() }} is already assigned to a parent {{ Naming.Asset.toLowerCase() }}.
      </p>
      <p>
        To detach this {{ Naming.Asset.toLowerCase() }} from its parent {{ Naming.Asset.toLowerCase() }}, click
        <router-link class="is-size-6 has-text-weight-bold"
          :to="{
            name: 'asset-tools',
            params: {
              asset: asset.id,
            }
          }">
          here.
        </router-link>
      </p>
      <p>
        To edit the parent {{ Naming.Asset.toLowerCase() }}'s components, click
        <router-link class="is-size-6 has-text-weight-bold"
            :to="{
            name: 'asset-overview',
            params: {
              asset: asset.asset_id,
            }
          }">
          here.
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'has-parent-asset',

  props: {
    asset: {
      type: Object,
    },
  }
}
</script>