<template>
  <loader v-bind="{ loading }">
    <div class="box">
      <div class="columns">
        <div class="column">
          <h3 class="title is-4">{{ Naming.Asset }} Transaction History</h3>
        </div>
        <div class="column is-narrow">
          <action-button
              slot="right"
              class="is-info"
              :working="downloadingHistory"
              @click="downloadAssetHistoryReport"
              left-icon="download">
            Pull Report (CSV)
          </action-button>
        </div>
      </div>
      <div v-if="transactionHistory.data" class="columns">
        <div class="column">
          <table class="table is-fullwidth">
            <thead>
            <tr>
              <th class="is-narrow">Date</th>
              <th>Transaction Type</th>
              <th>Transaction Details</th>
              <th class="is-narrow">User</th>
              <th class="is-narrow">{{ Naming.Job }} Number</th>
              <th class="is-narrow">{{ Naming.Job }} Type</th>
              <th class="is-narrow">{{ Naming.Job }} Reference</th>
            </tr>
            </thead>
            <tbody>
              <history-item v-for="item in transactionHistory.data" :key="item.id" :item="item"/>
            </tbody>
          </table>
          <div class="columns">
            <div class="column">
              <pager
                  :working="loadingPage"
                  :pageable="transactionHistory"
                  @nav="goToPage"
                  jump-controls
                  context="Transaction"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import {mapGetters} from 'vuex'
import HistoryItem from "@/views/asset/manager/partials/HistoryItem.vue";
import {asset as backend} from "@/api";
import download from "downloadjs";

export default {
  name: 'asset-history',

  components: {HistoryItem},

  data: () => ({
    loading: true,
    loadingPage: false,
    downloadingHistory: false
  }),

  created() {
    Promise.all([
      this.$store.dispatch('asset/loadAsset', this.$route.params.asset),
    ]).then(() => {
      this.loadTransactions()
    }).finally(() => {
      this.loading = false
    })
  },

  methods: {
    loadTransactions() {
      this.$store.dispatch('asset/loadTransactionHistory', {
        path: 'api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
        this.loadingPage = false
      })
    },
    goToPage(path) {
      this.loadingPage = true
      this.$router.push(path)
    },
    downloadAssetHistoryReport(){
      this.downloadingHistory = true
      backend.downloadAssetHistory({
        assetId: this.asset.id,
      }, ({ data }) => {
        this.downloadingHistory = false
        const date = moment().format('YYYY-MM-DD')
        download(data, `${date}-Asset-History-${this.asset.code}.csv`, 'text/csv')
      }, error => {
        this.$whoops()
        this.downloadingHistory = false
      })
    }
  },

  computed: {
    ...mapGetters('asset', ['asset', 'transactionHistory']),
  },

  watch: {
    '$route':'loadTransactions'
  }
}
</script>