<template>
  <section class="auth-view is-flex is-flex-direction-column align-center justify-center is-flex-wrap-wrap">
    <form class="grid gap-3 has-animation-present animation-delay-300" >
      <div class="has-text-centered">
        <img class="logo" :src="logoUrl" alt="">
      </div>
      <div class="box is-paddingless auth-box">
        <div class="box-header pt-2 pb-1 plr-2">Sign into your account</div>
        <div v-if="serverErrors" class="pb-2 notification-container">
          <div class="notification is-danger is-light">
            {{ serverErrors }}
          </div>
        </div>
        <div class="pl-2 pr-2 grid gap-1">
          <div class="field">
            <div class="control has-icons-left">
              <input
                  v-model="username"
                  :class="{'is-danger': submitted && v$.username.$invalid}"
                  class="input is-medium"
                  type="text"
                  placeholder="Email Address"
                  :disabled="loading"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
            </span>
            </div>
            <span class="help is-danger" v-if="submitted && v$.username.$invalid && v$.username.required">Email is required</span>
          </div>
          <div class="field">
            <div class="control has-icons-left">
              <input
                  v-model="password"
                  :class="{'is-danger': submitted && v$.password.$invalid}"
                  class="input is-medium"
                  type="password"
                  placeholder="Password"
                  :disabled="loading"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
            </span>
            </div>
            <span class="help is-danger" v-if="submitted && v$.password.$invalid && v$.password.required">Password is required</span>
          </div>
          <action-button
              v-bind="{ working: loading }"
              @click="signIn"
              class="is-primary is-rounded is-medium has-text-weight-bold">
            Sign In
          </action-button>
          <div class="is-flex align-center justify-center">
            <a @click="initialisePasswordReset" class="has-text-cool-blue-text">Forgot your Password?</a>
          </div>
        </div>

        <div class="box-footer pt-1 pb-2 plr-2 is-flex align-center justify-center">
          <p class="has-text-weight-light">Don’t have an account?</p>
          <action-button
              @click="getStarted"
              class="ml is-rounded auth-footer-button has-text-weight-bold"
              right-icon="arrow-right">
            Get Started
          </action-button>
        </div>
      </div>
      <footer>{{ `${appName} ${version}` }}</footer>
    </form>
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {

  setup() {
    return {v$: useVuelidate()}
  },

  data: () => ({
    username: '',
    password: '',
    submitted: false,
    working: false,
    version: process.env.VUE_APP_VERSION,
    errorMessage: ''
  }),


  validations() {
    return {
      username: {required},
      password: {required}
    }
  },

  methods: {
    signIn() {
      this.submitted = true
      if (!this.v$.$invalid) {
        this.working = true
        this.errorMessage = ''
        this.$store.dispatch('auth/login', {username: this.username, password: this.password}).then((data) => {
          if (data.two_factor === true) {
            this.$store.commit('auth/parkToken', data.token)
            return this.$router.push({
              name: 'two-factor-challange'
            })
          } else {
            this.loadCompany()
          }
        }).catch(error => {
          if (error.response.status === 401 || error.response.status === 400) {
            this.errorMessage = `Your password is incorrect, ${error.response.data['message']}`
          }
          if (error.response.status === 403) {
            this.errorMessage = `${error.response.data['message']}`
          }
          if (error.response.status === 404) {
            this.errorMessage = `There is no account with email ${this.username}`
          }
          this.working = false
        })
      }

    },

    loadCompany() {
      this.$store.dispatch('company/load').then(() => {
        this.$store.dispatch('namingConventions/getNamingConventions').then().catch(
            error => {
              console.error('Could not load Dynamic Naming Conventions.')
            }
        )
        this.working = false
        this.$router.push({name: 'dashboard'})
      }).catch(error => {
      })
    },

    initialisePasswordReset() {
      this.$router.push({
        name: 'forgot-password'
      })
    },

    getStarted() {
      this.$router.push({
        name: 'signup'
      })
    }
  },

  computed: {
    logoUrl() {
      return process.env.VUE_APP_COLOUR_LOGO
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
    serverErrors() {
      return this.errorMessage;
    },
    loading() {
      return this.working;
    }
  },

}
</script>

<style scoped>
/* Notification Container to control width */
.notification-container {
  max-width: 350px; /* Adjust this width to your preference */
  margin: 0 auto;
  text-align: center;
}

.notification {
  word-wrap: break-word;
}
</style>
