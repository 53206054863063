<template>
  <tr>
    <td class="is-narrow">
      <router-link class="has-text-link" :to="{
                  name: 'asset-type-dynamic-statuses',
                  params: {
                    assetType: asset.asset_type_id
                  }
                }">
        {{ status.name }}
      </router-link>
    </td>
    <td>{{ status.description }}</td>
    <td>{{ status.options.length }}</td>
    <td>
      <p>{{ assignedAt }}</p>
    </td>
    <td>
      <data-selector
          right-icon="chevron-down"
          v-model="selectedOptionId"
          @input="select"
          :disabled="!status.options.length || loading"
          :items="status.options"
          value-key="id"
          label-key="value">
        <action-button
            v-if="selectedOptionId"
            :disabled="loading"
            class="is-primary"
            slot="right"
            @click="clearOption()">
          <icon icon="times"/>
        </action-button>
      </data-selector>
    </td>
  </tr>
</template>
<script>

import {mapGetters} from "vuex";
import moment from "moment";

export default {
  props: {
    status: {
      type: Object,
      default: () => ({}),
    },
    activeStatuses: {
      type: Array,
      default: () => ({}),
    },
  },

  data: () => ({
    selectedOptionId: null,
    loading: false
  }),

  mounted() {
    this.setCurrentOption()
  },

  methods: {
    async select() {
      this.loading = true
      let payload = {
        option_id: this.selectedOptionId,
        asset_id: this.asset.id,
        status_id: this.status.id
      }
      await this.$store.dispatch('dynamicStatus/selectDynamicStatusOption', payload).then(() => {
        this.loading = false
        this.$emit('reload')
        this.$toast.success(`Active option updated`)
      })
    },
    setCurrentOption() {
      this.selectedOptionId = this.currentlySelectedOption?.id ?? null
    },
    clearOption() {
      this.selectedOptionId = null
      this.select()
    },
  },

  computed: {
    currentlySelectedOption() {
      return this.activeStatuses.find(activeStatus => activeStatus.dynamic_status_id === this.status.id)
    },
    assignedAt() {
      if (this.selectedOptionId && this.loading === false) {
        return moment(this.currentlySelectedOption?.pivot?.updated_at).format('Y-MM-DD HH:MM:SS')
      }
      return '-'
    },
    ...mapGetters('asset', ['asset']),
  },

}
</script>