<template>
  <loader v-bind="{ loading }">
    <div class="box p-2">
      <div class="is-flex justify-between">
        <div class="is-flex align-items-center">
          <h1 class="title is-size-4">Dynamic Statuses</h1>
          <icon
              icon="info-circle"
              class="ml-1 mb-3 is-small has-text-grey-lighter has-tooltip-right has-tooltip-multiline"
              :data-tooltip="statusesInfo"/>
        </div>
        <router-link class="button is-primary has-text-weight-medium"
                     v-if="$root.hasAbility('manage-dynamic-statuses', 'App\\Models\\DynamicStatus')"
                     :to="{
                        name: 'asset-type-dynamic-statuses',
                        params: {
                          assetType: asset.asset_type_id
                        }
                    }">
          <icon icon="angle-double-right" class="mr-1"/>
          Edit Dynamic Statuses
        </router-link>
      </div>

      <p class="has-text-grey-light mb-2" v-if="dynamicStatuses.length < 1">
        There are no dynamic statuses for this {{ Naming.Asset }}'s {{ Naming.AssetType }} yet.<br>
        <span v-if="$root.hasAbility('manage-dynamic-statuses', 'App\\Models\\DynamicStatus')">
          <router-link class="has-text-link"
                       :to="{
                          name: 'asset-type-dynamic-statuses',
                          params: {
                            assetType: asset.asset_type_id
                          }
                       }">
            Click here
          </router-link>
          to add a new dynamic status for this {{ Naming.AssetType }}.
        </span>
      </p>
      <div v-else>
        <table class="table is-fullwidth mb-2">
          <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Options Count</th>
            <th>Status Assigned At</th>
            <th>Selected Status</th>
          </tr>
          </thead>
          <tbody>
          <dynamic-status
              v-for="status in dynamicStatuses"
              :status="status"
              :activeStatuses="asset.active_dynamic_statuses"
              :key="status.id"
              @reload="reloadAsset"/>
          </tbody>
        </table>
      </div>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";
import DynamicStatus from "@/views/asset/manager/partials/DynamicStatus.vue";

export default {
  name: 'asset-history',

  components: {DynamicStatus},

  data: () => ({
    loading: true,
  }),

  created() {
    this.loadStatuses()
  },

  methods: {
    async loadStatuses() {
      this.loading = true
      await this.$store.dispatch('dynamicStatus/loadDynamicStatuses', this.asset.asset_type_id).then(() => {
        this.loading = false
      })
    },
    reloadAsset() {
      this.$store.dispatch('asset/loadAsset', this.$route.params.asset)
    }
  },
  computed: {
    ...mapGetters('asset', ['asset']),
    ...mapGetters('dynamicStatus', ['dynamicStatuses']),
    statusesInfo() {
      return this.Convert(this.$lang.assetType.dynamicStatuses) ?? ''
    },
  },
}
</script>