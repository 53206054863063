<template>
  <loader v-bind="{ loading }" text="Loading Sensors">
    <div class="box">
      <div class="columns">
        <div class="column">
          <h3 class="title is-4">Sensors</h3>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth">
            <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="sensor in sensors" :key="sensor.id">
              <td>
                <router-link
                    :to="{
                      name: 'asset-sensor-dashboard',
                      params: {
                        asset: asset.id,
                        sensor: sensor.id
                      }
                    }"
                >
                  {{ sensor.personal_name }} ({{ sensor.name }})
                </router-link>
              </td>
              <td>
                {{ sensor.description }}
              </td>
              <td>
                <dropdown-wrapper position="bottom-end">
                  <table-actions-trigger
                      slot="trigger"
                      slot-scope="{ isActive }"
                      v-bind="{ isActive }"/>
                  <dropdown-item
                      @click="editSensorPersonalName(sensor)">
                    <icon icon="edit"/>
                    <span>Rename</span>
                  </dropdown-item>
                </dropdown-wrapper>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="columns" v-if="sensors.length === 0">
            <div class="column has-text-centered">
              <p class="mtb-1">
                There are no sensors to display.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { asset as backend } from '@/api'
import download from 'downloadjs'
import { editSensorName } from '@/modals'

export default {

  data: () => ({
    loading: true,
    loadingPage: false,
  }),

  created() {
    this.loadSensors()
  },

  methods: {
    loadSensors() {
      this.loading = true

      this.$store
          .dispatch('asset/loadSensors', this.asset.id)
          .then(() => this.loading = false)
    },

    async editSensorPersonalName(sensor) {
      await editSensorName(sensor)
      this.loadSensors()
    },

    goToPage(path) {
      this.$router.push(path)
    },
  },

  computed: {
    ...mapGetters('asset', [
      'asset',
      'sensors'
    ])
  },

  watch: {
    '$route': 'loadInspections'
  }
}
</script>
