<template>
  <div class="sub-asset-item is-flex" :class="{'is-staged' : isStaged}">
    <div class="is-flex-desktop align-center justify-between">
      <icon icon="qrcode" class="is-size-6 grab-cursor"/>
      <div class="is-flex align-center">
        <p class="has-tooltip sub-asset-item-text"
           :data-tooltip="`Barcode: ${subAsset.barcode_data}`">
          {{ fieldToDisplay }}
        </p>
        <p class="ml-1 has-tooltip sub-asset-item-text"
           data-tooltip="Onboard Date">
          <icon class=" is-inline is-size-7" icon="calendar"/>
          {{ subAsset.created_at | date('Y-MM-DD') }}
        </p>
        <p class="ml-1 has-tooltip sub-asset-item-text is-capitalized"
           data-tooltip="Asset Type">
          <icon class=" is-inline is-size-7" icon="coins"/>
          {{ subAsset.type_name }}
        </p>
        <p v-if="!isAvailableItems"
           class="has-tooltip sub-asset-item-text is-capitalized"
           data-tooltip="Zone">
          <icon class="is-inline is-size-7" icon="bezier-curve"/>
          {{ subAsset.zone.name }}
          <br><span v-if="isStaged === true" class="new-tag">Not Saved</span>
        </p>
      </div>
    </div>
    <icon @click="pushToSelected" v-if="isAvailableItems === true"
          class="is-size-5 pl-1 pr-1 hover-highlight-success" icon="plus-circle"/>
    <icon @click="removeFromSelected" v-if="isAvailableItems === false" class="is-size-5 pl-1 pr-1 hover-highlight-danger"
          icon="times-circle"/>
  </div>
</template>

<script>
export default {
  name: 'sub-asset-item',

  props: {
    subAsset: {
      type: Object,
    },
    isAvailableItems: {
      type: Boolean,
      default: false
    },
    staged: {
      type: Array
    }
  },

  methods: {
    pushToSelected() {
      this.$emit('pushToSelected', this.subAsset)
    },
    removeFromSelected() {
      this.$emit('removeFromSelected', this.subAsset)
    },
  },

  computed: {
    fieldToDisplay(){
      const barcode = this.subAsset.barcode_data ?? this.subAsset.barcode.data;
      return this.subAsset.code ?? barcode
    },
    isStaged(){
      return this.staged?.includes(this.subAsset)
    }
  }
}
</script>