<template>
  <div class="column">
    <h4 class="title is-5">Decommission {{ Naming.Asset }}</h4>
    <p>
      When an {{ Naming.Asset.toLowerCase() }} has reached end of life you may mark it as
      decommissioned. A decommissioned {{ Naming.Asset.toLowerCase() }} can be restored at any time.
    </p>
    <p v-if="hasParentAsset">
      Decommissioning this component {{ Naming.Asset.toLowerCase() }} will detach it from its parent
      {{ Naming.Asset.toLowerCase() }}.
    </p>
    <action-button
      :disabled="!$root.hasAbility('decommission-assets', 'Billow\\Models\\Asset')"
      class="is-medium mt-1 is-danger"
      left-icon="skull-crossbones"
      @click="decommission">
      Initiate Decommission
    </action-button>
  </div>
</template>

<script>
import { decommissionAsset } from "@/modals";
import { mapGetters } from "vuex";

export default {
  methods: {
    async decommission() {
      if (await decommissionAsset()) {
        this.$router.push({
          name: "site-assets",
          params: { site: this.asset.zone.site_id },
        });
      }
    },
  },

  computed: {
    ...mapGetters("asset", ["asset"]),
    hasSubassets() {
      if (this.asset.sub_assets.length > 0) {
        return true;
      }
      return false;
    },
    hasParentAsset() {
      return this.asset.asset_id ? true : false;
    },
  },
};
</script>
