<template>
<div class="sign-in-page flex-column align-items-center justify-center is-flex is-fullheight relative">
  <div class="has-text-centered">
    <img class="logo" :src="logoUrl" alt="">
  </div>
  <div class="login-form box is-paddingless">
    <p class="login-prompt">To reset your password, please confirm your email address and choose a new password.</p>
    <form class="p-2">
      <div class="columns">
        <div class="column">
          <text-input
            type="email"
            classes="is-medium"
            placeholder="Email address"
            left-icon="envelope"
            left-icon-type="far"
            :value="resetPassword.email"
            :error="$root.errors.email"
            @input="resetPasswordEmail"
            :label="false"
          />

          <text-input
            type="password"
            classes="is-medium"
            placeholder="New Password"
            left-icon="asterisk"
            :value="resetPassword.password"
            :error="$root.errors.password"
            @input="resetPasswordPassword"
            :label="false"/>

          <text-input
            type="password"
            classes="is-medium"
            placeholder="Confirm your New Password"
            left-icon="asterisk"
            :value="resetPassword.password_confirmation"
            :error="$root.errors.password_confirmation"
            @input="resetPasswordPasswordConfirmation"
            :label="false"/>
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-centered is-flex justify-between align-items-center">
          <a class="has-text-grey-light is-flex align-items-center"
            @click="$router.replace({
              name: 'signin'
            })">
            <icon icon="chevron-left"/>
            <span>Cancel</span>
          </a>
          <submit-button
            :working="working"
            @submit="reset">
            Reset Password
          </submit-button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    base: process.env.VUE_APP_BASE_URL
  }),

  methods: {
    ...mapMutations('auth', [
      'resetPasswordEmail',
      'resetPasswordPassword',
      'resetPasswordPasswordConfirmation',
    ]),
    reset() {
      this.working = true
      this.$store.dispatch('auth/resetPassword', this.$route.params.token)
        .then(() => {
          this.$toast.success(this.$lang.auth.passwordResetComplete)
          this.$router.replace({ name: 'signin' })
        })
        .catch(console.error)
        .then(() => this.working = false)
    }
  },

  computed: {
    ...mapState('auth', [
      'resetPassword'
    ]),
    logoUrl() {
      return process.env.VUE_APP_COLOUR_LOGO
    },
  }

}
</script>
