<template>
  <div v-if="!hasSubassets" class="column">
    <div v-if="!hasParentAsset">
      <h4 class="title is-5">Assign to {{ Naming.Asset }}</h4>
      <p>
        {{ Naming.Assets }} can be assigned to a parent {{ Naming.Asset.toLowerCase() }} as an {{ Naming.Asset.toLowerCase() }} component. {{ Naming.Asset }} components can be detached from the parent {{ Naming.Asset.toLowerCase() }} to remove their component status.
      </p>
      <action-button
        class="is-warning is-medium mt-1" left-icon="angle-double-up" @click="assignAsset">
        Assign
      </action-button>
    </div>
    <div v-if="hasParentAsset" >
      <h4 class="title is-5">Detach from Parent {{ Naming.Asset }}</h4>
      <p>
        {{ Naming.Asset }} components can be detached from their parent {{ Naming.Asset.toLowerCase() }} to remove their component status.
      </p>
      <action-button
        class="is-warning is-medium mt-1" left-icon="angle-double-down" @click="detachAsset">
        Detach
      </action-button>
    </div>
  </div>
</template>

<script>
import { assignToParent } from '@/modals'
import { mapGetters } from 'vuex'

export default {
  methods: {
    assignAsset() {
      assignToParent()
    },
    async detachAsset() {
      if(await this.$confirm({
        title: this.Convert('Detach Asset'),
        message: this.Convert('Are you sure you want to detach this asset?')
      })) {
        this.working = true
        this.$store.dispatch('asset/unassignAsset', {asset_id: this.asset.id}).then(() => {
          this.$toast.success(this.Convert('Asset has been detached'))
          this.working = false
        }).catch(error => {
          this.working = false
          this.$whoops()
        })
      }
    },
  },

  computed: {
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('asset', [
      'asset'
    ]),    
    hasSubassets() {
      if (this.asset.sub_assets.length > 0) {
        return true;
      }
      return false;
    },
    hasParentAsset(){
      return this.asset.asset_id ? true : false
    }
  }
}
</script>
