<template>
  <div class="column">
    <h4 class="title is-5">Transfer {{ Naming.Asset }}</h4>
    <p v-if="!hasParentAsset">
      An {{ Naming.Asset.toLowerCase() }} may be transferred out of its current {{ Naming.Zone.toLowerCase() }}, into a different {{ Naming.Zone.toLowerCase() }}.
      The {{ Naming.Asset.toLowerCase() }} can be transferred to a {{ Naming.Zone.toLowerCase() }} in different {{ Naming.Site.toLowerCase() }}, or to a {{ Naming.Zone.toLowerCase() }} belonging to a different {{ Naming.Client.toLowerCase() }}, provided the {{ Naming.User.toLowerCase() }} has the necessary permissions.
      Note that the receiving {{ Naming.Zone.toLowerCase() }} must have the correct {{ Naming.AssetGroups.toLowerCase() }} configured.
      <span v-if="hasSubassets"><br>
        Transferring a parent {{ Naming.Asset.toLowerCase() }} will also transfer all attached {{ Naming.Asset.toLowerCase() }} components.
      </span>
    </p>
    <p v-if="hasParentAsset">
      {{ Naming.Asset }} components cannot be transferred individually. Either detach the
      component from the parent {{ Naming.Asset.toLowerCase() }} first, or transfer the parent {{ Naming.Asset.toLowerCase() }}, which
      will transfer all {{ Naming.Asset.toLowerCase() }} components.
    </p>
    <action-button
      :disabled="hasParentAsset"
      class="is-warning is-medium mt-1"
      left-icon="exchange-alt"
      @click="$router.push({ name: 'asset-transfer' })"
      >Initiate Transfer
    </action-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

async created() {
    await this.$store.dispatch('client/loadClientList')
    this.loading = false
  },

  computed: {
    ...mapGetters("site", ["site"]),
    ...mapGetters("asset", ["asset"]),
    ...mapGetters('client', ['clients']),

    hasSubassets() {
      if (this.asset.sub_assets.length > 0) {
        return true;
      }
      return false;
    },
    hasParentAsset() {
      return this.asset.asset_id ? true : false;
    },
  },
};
</script>
