<template>
  <loader :loading="loading" text="Loading Sensor">
    <div v-if="!loading">

      <!-- Page title -->
      <div>
        <div class="columns">
          <div class="column">
            <div class="title mb-0">
              {{ sensor.personal_name }} <small class="has-text-weight-light">({{ sensor.name }})</small><br>
              <span class="is-size-6 has-text-weight-normal">Sensor ID - {{ sensor.source_id }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Latest Readings -->
      <div class="columns">
        <div class="column">
          <div class="columns is-multiline">
            <div class="column is-4" v-for="(readingName, index) in Object.keys(latestSensorReadings)" :key="index">
              <component
                class="box"
                :is="`iot-widget-${kebabCase(readingName)}`"
                :latest-reading="readings.latest"
                :oldest-reading="readings.oldest"
                :last24-hour-readings="readings.last24Hours"
                :variable-name="readingName"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Latest Sensor Location -->
      <div class="box" v-if="locations.length > 0 && latestSensorLocation">
        <h3 class="title is-4">Latest Sensor Location</h3>

        <GmapMap
          :center="{ lat: +latestSensorLocation.latitude, lng: +latestSensorLocation.longitude }"
          :zoom="14"
          :options="googleMapOptions"
          map-type-id="terrain"
          style="width: 100%; height: 520px"
        >

          <GmapMarker
            v-for="(location, key) in locations"
            :key="key"
            :position="{ lat: +location.latitude, lng: +location.longitude }"
            :icon="getMarkerIcon(location)"
            @click="toggleInfoWindow(location, key)"
          />

          <gmap-polyline :path="googleMapsPolylinePath" :options="{ strokeColor:'#CCC'}"/>

          <gmap-info-window
            :options="googleMapsInfoWindow.options"
            :position="googleMapsInfoWindow.position"
            :opened="googleMapsInfoWindow.open"
            @closeclick="googleMapsInfoWindow.open=false"
          >
            <div v-html="googleMapsInfoWindow.content"></div>
          </gmap-info-window>

        </GmapMap>

      </div>

      <sensor-history-events :readings="readings" class="mt-2"/>
    </div>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce, first, kebabCase, map, reverse, sortBy, startCase } from 'lodash'
import defaultMapStyles from '@/theme/gmaps/default'
import moment from 'moment'
import SensorHistoryEvents from '../../../components/iot/SensorHistoryEvents'

export default {
  components: { SensorHistoryEvents },
  data: () => ({
    googleMapOptions: {
      styles: defaultMapStyles,
    },
    googleMapsInfoWindow: {
      options: {},
      position: { lat: 0, long: 0 },
      content: '',
      open: false,
    },
    loading: true,
    going_to_page: false,
  }),

  created() {
    this.load()
  },

  methods: {
    load() {
      this.loading = true
      this.going_to_page = true

      Promise.all([
        this.$store.dispatch('sensor/loadSensor', this.$route.params.sensor),
        this.$store.dispatch('sensor/loadReadings', {
          query: this.queryString,
          sensorId: this.$route.params.sensor,
        }),
        this.$store.dispatch('sensor/loadLocations', {
          query: this.queryString,
          sensorId: this.$route.params.sensor,
        }),
      ]).then(() => {
        this.loading = false
        setTimeout(() => this.going_to_page = false, 100)
      })
    },

    startCase(value) {
      return startCase(value)
    },

    toggleInfoWindow(location, index) {
      this.googleMapsInfoWindow.position = { lat: +location.latitude, lng: +location.longitude }
      this.googleMapsInfoWindow.content = location.read_at
      this.googleMapsInfoWindow.open = true
    },

    getMarkerIcon(location) {
      if (location.read_at === this.latestSensorLocation.read_at) {
        return { url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png' }
      }

      return { url: require('@/../public/darkened_maps_pin.png') }
    },

    mapVariableValue(variableName, value) {
      if (variableName.toLowerCase() === 'time') {
        return moment(value).format(`Y-MM-DD hh:mm:ss`)
      }

      if (variableName.toLowerCase() === 'runtime') {
        return (value / 60).toFixed(2)
      }

      if (variableName.toLowerCase().indexOf('voltage') !== -1) {
        return (value / 1000).toFixed(2)
      }

      if (variableName.toLowerCase().indexOf('temperature') !== -1) {
        return value.toFixed(0)
      }

      if (variableName.toLowerCase().indexOf('humidity') !== -1) {
        return value.toFixed(2)
      }

      return value
    },

    sensorReadingSuffix(variableName) {
      if (variableName.toLowerCase().indexOf('temperature') !== -1) {
        return `°C`
      }

      if (variableName.toLowerCase().indexOf('humidity') !== -1) {
        return `%`
      }

      if (variableName.toLowerCase().indexOf('runtime') !== -1) {
        return `h`
      }

      if (variableName.toLowerCase().indexOf('voltage') !== -1) {
        return 'v'
      }

      return ``
    },

    kebabCase(value) {
      return kebabCase(value)
    },
  },

  computed: {
    ...mapGetters('asset', [
      'asset',
    ]),
    ...mapGetters('sensor', [
      'sensor',
      'locations',
      'readings',
    ]),
    queryString() {
      let fullPath = this.$route.fullPath
      let startIndex = fullPath.indexOf('?')
      if (startIndex === -1) {
        return ''
      }
      return fullPath.substr(startIndex)
    },
    chartOptions() {
      return {
        chart: {
          id: 'readings-over-time',
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: value => value.toFixed(2),
          },
        },
        xaxis: {
          type: 'datetime',
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 4,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
      }
    },
    latestSensorLocation() {
      let locations = sortBy(this.locations, location => location.read_at)
      locations = reverse(locations)
      return first(locations)
    },
    googleMapsPolylinePath() {
      return map(this.locations, location => ({ lat: +location.latitude, lng: +location.longitude }))
    },

    latestSensorReadings() {
      return this.readings.latest.data
    },
  },

  watch: {
    '$route': 'load',
  },
}
</script>
