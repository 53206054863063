<template>
<loader v-bind="{ loading }" :text="`Loading ${Naming.Inspection} History`">
<div class="box">
  <div class="columns">
    <div class="column">
      <h3 class="title is-4">Deleted {{ Naming.Inspection }} History</h3>
    </div>
    <div class="column is-narrow">
      <router-link class="button is-small" :to="{ name: 'asset-inspections', params: { asset: assetId} }">
        {{ Naming.Inspection }} History
      </router-link>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{ Naming.Inspection }} #</th>
            <th>Date</th>
            <th>{{ Naming.Job }}</th>
            <th>{{ Naming.Site }}</th>
            <th>{{ Naming.Checklist }} Name</th>
            <th>{{ Naming.Technician }} Name</th>
            <th>Deleted At</th>
            <th>Deleted By</th>
            <th class="is-narrow">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="inspection in inspectionHistory.data" :key="inspection.id">
            <td>{{ inspection.id }}</td>
            <td>{{ inspection.created_at | date('L LTS') }}</td>
            <td>{{ inspection.job.number }}</td>
            <td>{{ inspection.job.site ? inspection.job.site.name : '-' }}</td>
            <td>{{ inspection.checklist.name }}</td>
            <td>{{ inspection.technician.name }}</td>
            <td>{{ inspection.deleted_at }}</td>
            <td>{{ inspection.deleted_by }}</td>
            <td>
              <action-button :disabled="recovering" @click="recoverInspection(inspection)" class="is-small">Recover</action-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="columns">
        <div class="column">
          <pager 
            v-if="inspectionHistory.data.length"
            :working="loadingPage" 
            :pageable="inspectionHistory"
            @nav="goToPage"
            jump-controls
            :context="Naming.Inspection.toLowerCase()"/>

            <p v-else class="has-text-centered mtb-1">
              There are no {{ Naming.Inspections.toLowerCase() }} to display.
            </p>
        </div>
      </div>
    </div>
  </div>
</div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { asset as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    loaded: false,
    loadingPage: false,
    downloadingInspection: false,
    range: '',
    recovering: false,
    assetId: ''
  }),

  created() {
    this.assetId = this.$route.params.asset
    this.loadInspections()
  },

  methods: {
    loadInspections() {

      this.$store.dispatch('asset/loadInspectionHistory', {
        path: `api/assets/${this.assetId}/trashed-inspections`
      }).then(() => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    recoverInspection(inspection) {
      this.recovering = true
      this.$toast.info('Recovery started')
      this.$store.dispatch('inspection/recover', inspection.id).then(() => {
        this.$toast.success('Recovery successful')
        this.$router.push({
          name: 'inspection-evidence',
          params: {
            inspection: inspection.id,
            site: inspection.job.site_id,
            zone: this.asset.zone_id,
            asset: this.asset.id
          }
        })
      }).catch(error => {
        this.$toast.error('Something went wrong during the recovery process. Please contact support.')
      })
    }
  },

  computed: {
    ...mapGetters('asset', [
      'asset',
      'inspectionHistory'
    ])
  },

  watch: {
    '$route': 'loadInspections'
  }
}
</script>
