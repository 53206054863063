<template>
  <loader v-bind="{ loading }" :text="`Loading ${Naming.Asset} Transfer Page`">
    <div class="box">
      <h3 class="title is-4">Transfer {{ Naming.Asset }} <small class="has-text-grey">({{ asset.code }})</small></h3>
      <hr>
      <div class="columns is-flex justify-between">
        <div class="column is-7">
          <div>
            <select-site 
              :asset="asset" 
              :site="site"
              @clientSelected="clientSelected"
              @siteSelected="siteSelected"
              @zoneSelected="zoneSelected"
              />
          </div>
        </div>
        <div class="column is-5">
          <message>
            <h4 class="is-size-5 title">{{ Naming.Asset }} Information:</h4>
            <h5><icon class="has-text-primary mb-1" icon="qrcode"/>  Code: <strong>{{ asset.code }}</strong></h5>
            <h5><icon class="has-text-primary mb-1" icon="address-book"/>  {{ Naming.Client }}: <strong>{{ asset.zone.site.client.legal_name }}</strong></h5>
            <h5><icon class="has-text-primary mb-1" icon="city"/>  {{ Naming.Site }}: <strong>{{ asset.zone.site.name }}</strong></h5>
            <h5><icon class="has-text-primary mb-1" icon="bezier-curve"/>  {{ Naming.Zone }}: <strong>{{ asset.zone.name }}</strong></h5>
            <h5 v-if="hasSubassets">
              <icon class="has-text-primary mb-1" icon="sitemap"/>  {{ Naming.Asset }} Components: <strong>{{subassetsCount}}</strong>
              <icon class="has-text-grey-lighter has-tooltip-multiline has-tooltip-left" icon="question-circle" :data-tooltip="`This ${Naming.Asset.toLowerCase()} has ${Naming.Asset.toLowerCase()} components attached to it. Transferring this ${Naming.Asset.toLowerCase()} to a new ${Naming.Zone.toLowerCase()} will also transfer all ${Naming.Asset.toLowerCase()} components.`"/>
            </h5>
            <h5>
              <icon class="has-text-primary mb-1" icon="coins"/>  {{ Naming.AssetTypes }} required in target {{ Naming.Zone.toLowerCase() }}:
              <icon class="has-text-grey-lighter mr-0 has-tooltip-multiline has-tooltip-left" icon="question-circle" :data-tooltip="`The target ${Naming.Zone.toLowerCase()} must have ${Naming.AssetTypes.toLowerCase()} set up that correspond with the ${Naming.Asset.toLowerCase()}\'s (and its components\') ${Naming.AssetType.toLowerCase()}. Only ${Naming.Zones.toLowerCase()} that are configured correctly will be shown in the dropdown menu`"/>
            </h5>
            <h5 
              class="is-size-6 ml-2" 
              v-for="assetType in transferringAssetTypes" 
              v-bind:key="assetType.id">
              <strong>- {{assetType.name}}</strong>
            </h5>
          </message>
          <message v-if="selectedClient" class="is-primary">
            <h4 class="is-size-5 title">Transferring To:</h4>
            <h5 >Client: <strong>{{ selectedClient.legal_name }}</strong></h5>
            <h5 v-if="selectedSite">{{ Naming.Site }}: <strong>{{ selectedSite.name }}</strong></h5>
            <h5 v-if="selectedZone">{{ Naming.Zone }}: <strong>{{ selectedZone.name }}</strong></h5>
          </message>
          <div v-if="selectedClient" class="is-flex justify-end mt-1" >      
            <submit-button
              class="is-success is-rounded"
              :disabled="selectedZone === null"
              @submit="requestTransfer"
              :working="isTransferring"
              left-icon="exchange-alt">Transfer {{ Naming.Asset }}
            </submit-button>
          </div>
        </div>
      </div>
    </div>  
  </loader>
</template>

<script>
import { mapGetters } from "vuex";
import SelectSite from "../partials/SelectSite";


export default {
  components: {
    SelectSite,
  },

  data: () => ({
    loading: true,
    isTransferring: false,
    selectedClient:null,
    selectedSite: null,
    selectedZone: null,
    zones : [],
  }),

  methods: {
    async requestTransfer(){
      if (this.hasSubassets) {
        if (await this.$confirm({
          title: 'Asset Components',
          message: `This asset has asset components attached to it. Transferring this asset to a new zone will also transfer all asset components. Do you want to proceed?`
        })){
          this.actionTransfer()
          return
        }
      }
      this.actionTransfer()
    },
    actionTransfer() {
      this.isTransferring = true
      this.$store.dispatch("asset/transfer", this.selectedZone.id).then(() => {
        this.isTransferring = false
        this.$toast.success(this.Convert("Asset Transferred"))
        this.$router.push({
          name: "asset-overview",
          params: {
            asset: this.asset.id,
          },
        })
      }).catch(() => {
        this.$whoops()
        this.isTransferring = false
      })
    },
    clientSelected(client){
      this.selectedClient = client
    },
    siteSelected(site){
      this.selectedSite = site
    },
    zoneSelected(zone){
      this.selectedZone = zone
    }
  },

  computed: {
    ...mapGetters("asset", ["asset"]),
    ...mapGetters("site", ["site"]),
    ...mapGetters("client", ["clientList"]),
    ...mapGetters("auth", ["user"]),
    transferringAssetTypes() {
      let assetTypes = [];
      assetTypes.push(this.asset.type);
      _.forEach(this.asset.sub_assets, (asset) => assetTypes.push(asset.type));
      return _.uniqBy(assetTypes, "id");
    },
    hasSubassets() {
      return this.asset.sub_assets.length > 0 ? true : false
    },
    subassetsCount(){
      return this.asset.sub_assets.length
    }   

  },

  async created() {
    await Promise.all([
      this.$store.dispatch("client/loadClientList"),
      this.$store.dispatch("asset/loadAsset", this.$route.params.asset),
    ])
    this.loading = false
  },

}
</script>
