<template>
<loader v-bind="{ loading }">
  <div class="box">
    <div class="columns">
      <div class="column">
        <h4 class="is-size-4 has-text-weight-light">{{ Naming.Asset }} Information</h4>
      </div>
    </div>
    <form>
      <div class="columns">
        <div class="column">
          <text-input 
            :error="$root.errors.code"
            :value="asset.code"
            @input="code">
            Identification No.
          </text-input>
        </div>
        <div class="column">
          <data-selector
            :value="asset.zone_id"
            @input="zone"
            :items="site.zones"
            label-key="name"
            value-key="id">{{ Naming.Zone }}</data-selector>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <select-input 
            no-revert
            :items="riskLevelList"
            value-key="id"
            label-key="level"
            :error="$root.errors.code"
            :value="asset.risk_id" 
            @input="risk">
            Risk Level
          </select-input>
        </div>
        <div class="column">
          <select-input 
            no-revert
            :items="assetTypeList"
            value-key="id"
            label-key="name"
            :error="$root.errors.asset_type_id"
            :value="asset.asset_type_id" 
            @input="assetType">
            {{ Naming.AssetType }}
          </select-input>
        </div>
      </div> 
    </form>

    <div v-if="assetTypeHasMetadata === true">
      <div>
        <h4 class="is-size-4 has-text-weight-light mt-1">Attributes</h4>
        <p class="has-text-grey">Custom attributes are derived from the {{ Naming.AssetType }}</p>
      </div>
      <div class="mt-1">
        <meta-field
          :field="field"
          v-for="(field, index) in asset.type.custom_fields_object"
          :key="index"/>
      </div>
    </div>
  </div> 

  <div class="columns">
    <div class="column">
      <submit-button :working="working" class="is-rounded is-success" left-icon="check" @submit="save">Update {{ Naming.Asset }}</submit-button>
    </div>
  </div>
</loader>
 
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import MetaField from '../partials/MetaField'

export default {

  components: {
    MetaField
  },

  data: () => ({
    working: false,
    loading: true
  }),
  
  beforeCreate() {
    Promise.all([
      this.$store.dispatch('riskLevel/loadRiskLevelList'),
      this.$store.dispatch('assetType/loadAssetTypeList')
    ]).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('asset', [
      'code',
      'zone',
      'risk',
      'assetType'
    ]),

    save() {
      this.working = true
      this.$store.dispatch('asset/save').then(() => {
        this.working = false
        this.$toast.success('Saved')
      }).catch(error => {
        this.working = false
        this.$toast.error('Whoops')
      })
    }
  },

  computed: {
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('asset', [
      'asset'
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList'
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ]),
    assetTypeHasMetadata(){
        return this.asset?.type?.custom_fields_object?.length > 0
    },
  }

}
</script>