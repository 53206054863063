<template>
<div class="sign-in-page flex-column align-items-center justify-center is-flex is-fullheight relative">
  <div class="login-logo">
    <img class="logo" :src="logoUrl">
  </div>
  <div class="login-form box is-paddingless">
    <div class="login-prompt content is-marginless">
      <p class="has-text-weight-bold">Welcome to {{ appName }}!</p>
      <p>As this is the first time you’re logging in, you’ll need to change your password. Enter your new password below and we’ll get you started.</p>
    </div>
    <div class="columns p-2">
      <div class="column">
          <text-input
            :type="visible ? 'text' : 'password'"
            placeholder="New Password"
            left-icon="asterisk"
            :value="changePassword.password"
            :error="$root.errors.password"
            @input="newPassword"
            :label="false">
            <div class="buttons has-addons" slot="right">
              <action-button
                @click="visible = !visible">
                <icon :icon="visible ? 'eye' : 'eye-slash'"/>
              </action-button>
              <submit-button
                v-bind="{ working }"
                @submit="setPassword">
                Set Password
              </submit-button>
            </div>
          </text-input>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    visible: false,
    base: process.env.VUE_APP_BASE_URL
  }),

  methods: {
    ...mapMutations('auth', [
      'newPassword',
    ]),
    setPassword() {
      this.working = true
      this.$store.dispatch('auth/firstPasswordChange')
        .then(async () => {
          this.$toast.success(this.$lang.auth.passwrdChanged)
          await this.$store.dispatch('auth/loadUser')
          this.$router.replace({ name: 'dashboard' })
        })
        .catch(console.error)
        .then(() => this.working = false)
    }
  },

  computed: {
    ...mapState('auth', [
      'changePassword'
    ]),
    appName() {
      return process.env.VUE_APP_NAME
    },
    logoUrl() {
      return process.env.VUE_APP_NAV_LOGO
    },
  }

}
</script>
