var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.Naming.Asset)+" Already Assigned as Component")]),_c('div',{staticClass:"has-text-"},[_c('p',[_vm._v(" This "+_vm._s(_vm.Naming.Asset.toLowerCase())+" is already assigned to a parent "+_vm._s(_vm.Naming.Asset.toLowerCase())+". ")]),_c('p',[_vm._v(" To detach this "+_vm._s(_vm.Naming.Asset.toLowerCase())+" from its parent "+_vm._s(_vm.Naming.Asset.toLowerCase())+", click "),_c('router-link',{staticClass:"is-size-6 has-text-weight-bold",attrs:{"to":{
          name: 'asset-tools',
          params: {
            asset: _vm.asset.id,
          }
        }}},[_vm._v(" here. ")])],1),_c('p',[_vm._v(" To edit the parent "+_vm._s(_vm.Naming.Asset.toLowerCase())+"'s components, click "),_c('router-link',{staticClass:"is-size-6 has-text-weight-bold",attrs:{"to":{
          name: 'asset-overview',
          params: {
            asset: _vm.asset.asset_id,
          }
        }}},[_vm._v(" here. ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }