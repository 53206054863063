<template>
  <div>
    <div v-for="status in sorted" :key="status.id">
      <p><span class="has-text-weight-semibold">{{ status.dynamic_status.name }}:</span> {{ status.value }}</p>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    activeStatuses: {
      type: Array,
      default: () => ({}),
    },
  },
  computed: {
    sorted(){
      return this.activeStatuses.sort((a, b) => {
        const nameA = a.dynamic_status.name.toUpperCase();
        const nameB = b.dynamic_status.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    }
  }
}
</script>