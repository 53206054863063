<template>
  <section class="auth-view is-wide is-flex flex-column align-center justify-center is-fullheight">
    <form class="grid gap-3 has-animation-present animation-delay-300">
      <div class="has-text-centered">
        <router-link to="/">
          <img class="logo" :src="logoUrl">
        </router-link>
      </div>
      <div class="box is-wide is-paddingless">
        <div class="box-header pt-2 pb-1 plr-2">
          <p class="has-text-weight-bold">Get Started with {{ appName }}</p>
          <p class="is-size-6 has-text-grey-darker mt-1">Please complete the form below. Once we've reviewed your application,<br class="is-hidden-mobile"> an {{ appName }} representative will make contact with you to complete the signup process.</p>
        </div>
        <div class="p-2 grid gap-1">
          <div class="grid gap-1 has-3-columns">
            <text-input
              class="is-marginless"
              autocomplete="please-stop-auto-completing"
              role="presentation"
              required
              placeholder="Full Name"
              left-icon="user-circle"
              left-icon-type="far"
              :value="enquiry.full_name"
              @input="fullName"
              :error="$root.errors.full_name">
              Full Name
            </text-input>
            <text-input
              class="is-marginless"
              autocomplete="please-stop-auto-completing"
              role="presentation"
              required
              placeholder="Email"
              left-icon="envelope"
              left-icon-type="far"
              :value="enquiry.email"
              @input="email"
              :error="$root.errors.email">
              Email Address
            </text-input>
            <intl-phone
              class="is-marginless"
              required
              :number="enquiry.mobile"
              :code="enquiry.dialing_code"
              @code="dialingCode"
              @number="mobile"/>
          </div>
          <div class="grid gap-1 has-2-columns">
            <text-input
              class="is-marginless"
              autocomplete="please-stop-auto-completing"
              role="presentation"
              required
              placeholder="Company Name"
              left-icon="building"
              left-icon-type="far"
              :value="enquiry.company_name"
              @input="companyName"
              :error="$root.errors.company_name">
              Company Name
            </text-input>
            <data-selector
              class="is-marginless"
              autocomplete="please-stop-auto-completing"
              role="presentation"
              required
              left-icon="users"
              :items="company_sizes"
              :value="enquiry.company_size"
              @input="companySize"
              :error="$root.errors.company_size">
              Company Size
            </data-selector>
            <data-selector
              required
              class="is-marginless"
              autocomplete="please-stop-auto-completing"
              role="presentation"
              prompt-label="Select Country"
              searchable
              :error="$root.errors.country_id"
              :value="enquiry.country_id"
              @input="country"
              :items="countries"
              value-key="id"
              label-key="name">
              Country
            </data-selector>
            <div class="grid gap-1" :class="{ 'has-2-columns': enquiry.industry_id === 12 }">
              <data-selector
                required
                searchable
                class="is-marginless"
                autocomplete="please-stop-auto-completing"
                role="presentation"
                prompt-label="Select Industry"
                :error="$root.errors.industry_id"
                :value="enquiry.industry_id"
                @input="industry"
                :items="industries"
                value-key="id"
                label-key="name">
                Industry
              </data-selector>
              <text-input
                v-if="enquiry.industry_id === 12"
                class="is-marginless"
                autocomplete="please-stop-auto-completing"
                role="presentation"
                required
                :value="enquiry.industry_other"
                @input="industryOther"
                :error="$root.errors.industry_other">
                Specify Industry
              </text-input>
            </div>
          </div>
          <text-area
            class="is-marginless"
            autocomplete="please-stop-auto-completing"
            role="presentation"
            required
            rows="3"
            classes="has-fixed-size"
            :value="enquiry.company_bio"
            @input="companyBio"
            :error="$root.errors.company_bio">
            Tell us a bit about your company
          </text-area>
          <div class="buttons is-centered">
            <action-button
              v-bind="{ working }"
              @click="signUp"
              right-icon="arrow-right"
              class="is-primary is-rounded is-medium has-text-weight-bold">
              Submit Enquiry
            </action-button>
          </div>
        </div>
      </div>
      <footer>{{ appName }} {{ version }}</footer>
    </form>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    version: process.env.VUE_APP_VERSION,
  }),

  beforeMount() {
    this.$store.dispatch('list/loadCountries')
    this.$store.dispatch('list/loadIndustries')
  },

  methods: {
    ...mapMutations('accountEnquiry', [
      'fullName',
      'email',
      'mobile',
      'companyName',
      'dialingCode',
      'country',
      'industry',
      'industryOther',
      'companySize',
      'companyBio',
      'clear'
    ]),

    signUp() {
      this.working = true
      this.$store.dispatch('accountEnquiry/submitEnquiry').then(async () => {
        this.clear()
        this.working = false
        await this.$alert(
          'Enquiry Received',
          'Your application has been sent for processing. A representative from ' + this.appName + ' will be in touch soon!'
        )
        this.$router.push({
          name: 'signin'
        })
      }).catch(error => {
        this.working = false
      })
    }
  },

  computed: {
    ...mapState('accountEnquiry', [
      'enquiry'
    ]),
    ...mapState('list', [
      'countries',
      'industries',
      'company_sizes'
    ]),
    appName() {
      return process.env.VUE_APP_NAME
    },
    logoUrl() {
      return process.env.VUE_APP_COLOUR_LOGO
    },
  },

}
</script>
